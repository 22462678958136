import React, { useContext, useEffect, useRef, useState } from 'react'
import leftArrow from "../../../../images/leftArrow.svg"
import newcurriculamImage from "../../../../images/newcurriculam.svg"
import ButtonComponent from '../../../../@core/components/button/ButtonComponent'
import { useLocation, useNavigate, useBeforeUnload } from 'react-router-dom'
import loadable from "react-loadable"
import CustomInput from '../../../../@core/components/CustomInput/CustomInput'
import DropDown from '../../../../@core/components/DropDown/DropDown'
import CardWithImage from '../../../../@core/components/common/CardWithImage'
import Toggle from 'react-toggle'
import * as TablerIcons from "@tabler/icons-react"
import ModalAssign from './ModaAssign'
import UploadFile from '../UploadFile'
import { X, XCircle } from "react-feather"
import axios from 'axios'
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io"
import { Trash2, Plus, Link2 } from 'react-feather'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import CustomInputWithIncDec from '../../../../@core/components/CustomInputWithIncDec'
import { RxFileText } from "react-icons/rx"
import { InfinitySpin } from "react-loader-spinner"
import "./NewCurriculam.css"
import 'react-quill/dist/quill.snow.css'
import Select from 'react-select'
import { handleMangeCrudRight } from '../../../../Utility/Utils'
import { confirmAlert } from 'react-confirm-alert'
import DotsSixVertical from "./DotsSixVertical.svg"
import VideoIcon from "./Icons/VideoIcon.svg"
import ImageIcon from "./Icons/ImageIcon.svg"
import demoImg from "./Icons/demoImg.png"
import PdfIcon from "./Icons/PdfIcon.svg"
import { Upload } from "react-feather"
import ViewFilesTopic from './ViewFiles/ViewFilesTopic'
import TextEditor from "../../../../@core/components/TextEditor/TextEditor"
import { UserContext } from '../../../../context/UserContext'
const SpinnerComponent = () => {
    return (
        <div className="spinner-component">
            <InfinitySpin
                visible={true}
                width="200"
                color="#0258a1"
                ariaLabel="infinity-spin-loading"
            />
        </div>
    );
};
const UploadMediaLink = loadable({
    loader: () => import(/* webpackChunkName: 'Curriculum' */ "./UploadMediaLink"),
    loading: () => <></>,
});


const NewCurriculam = () => {
    const { getUserData } = useContext(UserContext);
    const parentId = "3"
    const moduleId = "4"
    const userType = getUserData.userType
    const crud_rights = getUserData.user_data?.crud_rights
    const baseUrl = process.env.REACT_APP_API_URL
    const userData = useSelector(state => state.auth.userData)
    const authentication_key = localStorage.getItem('authentication_key');
    const navigation = useNavigate()
    const location = useLocation()
    const [flag, setFlag] = useState(false)
    const [isInputFocused, setIsInputFocused] = useState("");
    const [newCurriculamState, setNewCurriculamState] = useState('basic-details')
    const [country, setCountry] = useState(null)
    const [editId, setEditId] = useState("")
    const [show, setShow] = useState(false)
    const [title, setTitle] = useState("")
    const [selectCate, setSelectCate] = useState("");
    const [categoryDropdown, setCategoryDropdown] = useState([])
    const [classDropdown, setClassDropdown] = useState([])
    const [classId, setClassId] = useState("")
    const [description, setDescription] = useState("")
    const [content_status, setContent_status] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [addFiles, setAddFiles] = useState(false)
    const [subjectArray, setSubjectArray] = useState([])
    const [isFileModal, setIsFileModal] = useState(false)
    const [showTopicImg, setShowTopicImg] = useState([])
    const [isActiveNext, setIsActiveNext] = useState(false)
    const [draftStatus, setDraftStatus] = useState("1")
    const [load, setLoad] = useState(false)
    const [mediaLinkModalOpen, setMediaLinkModalOpen] = useState(false)
    const [visibleTopics, setVisibleTopics] = useState({});
    const [activeSubjects, setActiveSubjects] = useState([]);
    const [activeTopics, setActiveTopics] = useState({});
    const [selectedType, setSelectedType] = useState({});
    const [activeActions, setActiveActions] = useState({});
    const [activeSubTopic, setActiveSubTopic] = useState(null); // To track the visible subtopic
    const [dropdownVisible, setDropdownVisible] = useState([]);
    const dropdownRef = useRef(null);
    const [notesDemoMark, setNotesDemoMark] = useState(false)
    const [options, setOptions] = useState([]);
    const [actionsDrowdown, setActionDropdown] = useState([])
    const [areaOfLearning, setAreaOfLearning] = useState([])
    const [topicFileData, setTopicFileData] = useState(false)
    const [openView, setOpenView] = useState(false)
    const [viewFileData, setViewFileData] = useState([]);
    const [handbookUpload, setHandBookUpload] = useState(false)
    const [optionRights, setOptionRights] = useState([])
    // console.log("showTopicImg", showTopicImg)
    // console.log(actionsDrowdown)
    // Define your duration array
    const duration = [
        { label: "5 min", value: "300" },
        { label: "10 min", value: "600" },
        { label: "15 min", value: "900" },
        { label: "20 min", value: "1200" },
        { label: "25 min", value: "1500" },
        { label: "30 min", value: "1800" },
        { label: "35 min", value: "2100" },
        { label: "40 min", value: "2400" },
        { label: "45 min", value: "2700" },
        { label: "50 min", value: "3000" },
        { label: "55 min", value: "3300" },
        { label: "60 min", value: "3600" },
        { label: "65 min", value: "3900" },
        { label: "70 min", value: "4200" },
        { label: "75 min", value: "4500" },
        { label: "80 min", value: "4800" },
        { label: "85 min", value: "5100" },
        { label: "90 min", value: "5400" },
        { label: "95 min", value: "5700" },
        { label: "100 min", value: "6000" }
    ];

    const [newDemoData, setNewDemoData] = useState([{
        name: "",
        topics: [
            {
                name: "",
                topic_days: 0,
                topic_grace: 0,
                file_data: [],
                sub_topics: [
                    {
                        name: "",
                        topic_days: 0,
                        sub_topic_type: "Sub Topic",
                        topic_grace: 0,
                        file_data: [],
                        skill_data: [],
                        learning_data: [],
                        mins: "",
                        diary_note: "",
                        gallery: "",
                    }
                ]
            }
        ]
    }])
    console.log(newDemoData)
    useEffect(() => {
        if (crud_rights && crud_rights.length > 0) {
          const settingRight = JSON.parse(crud_rights)[parentId][moduleId]
          // console.log("settingRight", settingRight)
          if (settingRight && settingRight.length > 0) {
            setOptionRights(settingRight)
          }
        }
        // optionRights ["0","4"]
      }, [crud_rights])
    const [isBlocking, setIsBlocking] = useState(true);
    const [touchStart, setTouchStart] = useState({ x: 0, y: 0 });
    const [touchEnd, setTouchEnd] = useState({ x: 0, y: 0 });

    const handleIconNameChange = (iconName) => {
        // Convert kebab-case to PascalCase
        const convertToPascalCase = (kebab) => {
            return (
                "Icon" +
                kebab
                    .split("-")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join("")
            );
        };

        // Convert the icon name to PascalCase
        const pascalCaseIcon = convertToPascalCase(iconName);

        // Dynamically retrieve the Tabler icon component
        const IconComponent = TablerIcons[pascalCaseIcon];

        // Return the component if it exists, otherwise return null
        return IconComponent ? IconComponent : null;
    };
    // Block navigation on reload/refresh
    useBeforeUnload((event) => {
        if (isBlocking) {
            event.preventDefault();
            event.returnValue = ""; // Standard way to prompt user
        }
    });

    const handleBeforeUnload = (event) => {
        if (isBlocking) {
            event.preventDefault();
            event.returnValue = ""; // This triggers the default browser confirmation dialog
        }
    };

    // Attach navigation blocking for back/forward navigation
    useEffect(() => {
        const handleBlockNavigation = (event) => {
            const confirmationMessage = "Do you really want to leave this page?";
            if (isBlocking) {
                const userConfirmed = window.confirm(confirmationMessage);
                if (userConfirmed) {
                    navigation(-1); // Navigate back if the user confirms
                } else {
                    event.preventDefault(); // Prevent the navigation if the user cancels
                }
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handleBlockNavigation);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handleBlockNavigation);
        };
    }, [isBlocking, navigation]);

    // Detect swipe gestures to confirm navigation
    useEffect(() => {
        const handleTouchStart = (e) => {
            setTouchStart({ x: e.touches[0].clientX, y: e.touches[0].clientY });
        };

        const handleTouchEnd = (e) => {
            setTouchEnd({ x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY });
        };

        const detectSwipeGesture = () => {
            const xDiff = touchStart.x - touchEnd.x;
            const yDiff = touchStart.y - touchEnd.y;

            // Detect a significant horizontal swipe (ignore vertical swipes)
            if (Math.abs(xDiff) > Math.abs(yDiff) && Math.abs(xDiff) > 50) {
                if (xDiff > 0) {
                    // Swipe left
                    const confirmationMessage = "Do you really want to navigate left?";
                    if (window.confirm(confirmationMessage)) {
                        navigation(-1); // Navigate back on swipe left
                    }
                } else {
                    // Swipe right (optional)
                    const confirmationMessage = "Do you really want to navigate right?";
                    if (window.confirm(confirmationMessage)) {
                        navigation(1); // Navigate forward on swipe right
                    }
                }
            }
        };

        window.addEventListener('touchstart', handleTouchStart);
        window.addEventListener('touchend', handleTouchEnd);

        // Detect swipe direction on touch end
        if (touchEnd.x !== 0) {
            detectSwipeGesture();
        }

        return () => {
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchend', handleTouchEnd);
        };
    }, [touchStart, touchEnd, isBlocking, navigation]);
    const modules = {
        toolbar: false
    }
    // Handle back button click (left arrow) and trigger confirmation
    const handleStopBlocking = (e) => {
        e.preventDefault(); // Prevent default link behavior
        const confirmationMessage = "Do you really want to leave this page?";
        if (window.confirm(confirmationMessage)) {
            navigation(-1); // Navigate back if user confirms
        }
    };

    const handleDropdownToggle = (subjectIndex, topicIndex, subTopicIndex) => {
        setShowTopicImg([subjectIndex, topicIndex, subTopicIndex]);  // Track the current subtopic
        const key = `${subjectIndex}-${topicIndex}-${subTopicIndex}`;
        setDropdownVisible(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };

    const handleActionClick = (actionId, subjectIndex, topicIndex, subTopicIndex) => {
        setShowTopicImg([subjectIndex, topicIndex, subTopicIndex]); // Track the current subtopic

        // Determine if the clicked action is an upload (for which you handle files differently)
        const isUploadAction = actionId === "258";

        // If it's not the Upload Files action, handle the note field
        if (!isUploadAction) {
            const currentFileData = newDemoData[subjectIndex]?.topics[topicIndex]?.sub_topics[subTopicIndex]?.file_data || [];
            const existingEntry = currentFileData.find(file => file.note_id === actionId);

            // If an existing entry is found, remove it; otherwise, create a new entry
            if (existingEntry) {
                // Remove the existing entry from the file_data
                handleInputTextBoxNew('', subjectIndex, topicIndex, subTopicIndex, actionId, actionId, false);
            } else {
                // Create a new entry
                handleInputTextBoxNew('', subjectIndex, topicIndex, subTopicIndex, actionId, actionId, false);
            }
        }

        // Toggle active state in the dropdown (adjusting logic for dynamic actionId)
        setActiveActions((prevState) => {
            const currentActions = prevState[subjectIndex]?.[topicIndex]?.[subTopicIndex] || [];

            // Remove action if it already exists, else add it to the list
            const updatedActions = currentActions.includes(actionId)
                ? currentActions.filter((a) => a !== actionId)
                : [...currentActions, actionId];

            return {
                ...prevState,
                [subjectIndex]: {
                    ...prevState[subjectIndex],
                    [topicIndex]: {
                        ...prevState[subjectIndex]?.[topicIndex],
                        [subTopicIndex]: updatedActions
                    }
                }
            };
        });

        // Toggle dropdown visibility
        setDropdownVisible(prevState => ({
            ...prevState,
            [`${subjectIndex}-${topicIndex}-${subTopicIndex}`]: !prevState[`${subjectIndex}-${topicIndex}-${subTopicIndex}`]
        }));
    };
    // Helper function to group files for display without affecting original indices
const groupFilesForDisplay = (fileData) => {
    const groups = [];
    let tempGroup = [];

    fileData.forEach((fileEntry) => {
        // Add fileEntry to the temporary group if it’s a file
        if (fileEntry.note_id === "258") {
            tempGroup.push(fileEntry);
            // Push pairs or remaining single files into groups
            if (tempGroup.length === 2) {
                groups.push([...tempGroup]);
                tempGroup = [];
            }
        } else {
            // Push text entries or any remaining group of files
            if (tempGroup.length > 0) {
                groups.push([...tempGroup]);
                tempGroup = [];
            }
            groups.push([fileEntry]); // Text box in a separate full-width group
        }
    });

    // Add any remaining files as a single group
    if (tempGroup.length > 0) groups.push([...tempGroup]);

    return groups;
};


    const renderActiveActions = (parentIndex, i, subIndex) => {
        const fileData = newDemoData[parentIndex]?.topics[i]?.sub_topics[subIndex]?.file_data || [];
        console.log(fileData)
        // Group files for display layout only
        const groupedFiles = groupFilesForDisplay(fileData);
        console.log("groupedFiles", groupedFiles)
        return groupedFiles.map((group, groupIndex) => {
            // Determine if it's a single full-width item or a row of items
            const rowClass = group.length === 1 ? "full-width" : "file-container-row";
    
            return (
                <div className={rowClass} key={`file-row-${groupIndex}`}>
                    {group.map((fileEntry, fileIndex) => {
                        const globalIndex = fileData.indexOf(fileEntry); // Stable index in original array
    
                        if (fileEntry.note_id === "258") {
                            // Render files (either full-width or side-by-side)
                            return (
                                <div className={group.length === 1 ? "file-container full-width" : "file-container file-item"} key={`file-item-${fileIndex}`}>
                                    <div className="file-container mt-4 d-flex mb-4 file-view" style={{ backgroundColor: "white", alignItems: "center", flexDirection: "column", borderRadius: "8px" }}>
                                        <div style={{width:"100%", height:"275px"}}>
                                            {fileEntry.file_type === "vimeo" || fileEntry.file_type === "youtube" ? (
                                                <img src={fileEntry.thumbnail ? fileEntry.thumbnail : VideoIcon} alt="Video Icon" height={275} width={"100%"} style={{ borderRadius: "8px 8px 0px 0px", objectFit:"cover" }} />
                                            ) : fileEntry.file_type === "png" || fileEntry.file_type === "jpeg" ? (
                                                <img src={fileEntry.file_path ? fileEntry.file_path : ImageIcon} alt="Image Icon" height={275} width={"100%"} style={{ borderRadius: "8px 8px 0px 0px" }} />
                                            ) : fileEntry.file_type === "pdf" ? (
                                                <img src={PdfIcon} alt="PDF Icon" height={275} width={"100%"} style={{ borderRadius: "8px 8px 0px 0px" }} />
                                            ) : null}
                                        </div>
                                        <div className="d-flex justify-content-between w-100 p-2">
                                            {fileEntry.file_type === "vimeo" || fileEntry.file_type === "youtube" ? (
                                                <img src={VideoIcon} alt="Video Icon" />
                                            ) : fileEntry.file_type === "png" || fileEntry.file_type === "jpeg" ? (
                                                <img src={ImageIcon} alt="Image Icon" />
                                            ) : fileEntry.file_type === "pdf" ? (
                                                <img src={PdfIcon} alt="PDF Icon" />
                                            ) : null}
                                            <CardWithImage
                                                onClickDemo={() => handleDemoContentSubTopic(parentIndex, i, subIndex, globalIndex, "markAsDemo")}
                                                title={fileEntry.file_name}
                                                btnType={fileEntry.mark_demo}
                                                download={fileEntry.for_download}
                                                data={fileEntry}
                                                type={fileEntry.file_type}
                                                onDownload={() => handleDemoContentSubTopic(parentIndex, i, subIndex, globalIndex, "downloadable")}
                                                onRemove={() => {
                                                    editId && fileEntry.id ? handleDeleteFile(fileEntry.id) : removeContentCard(globalIndex, "sub_topic");
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            );
                        } else if ( !fileEntry.hasOwnProperty("gallery") && !fileEntry.hasOwnProperty("diary_note")) {
                            // Render text entries in full-width containers
                            let matchedAction = actionsDrowdown.find((dynamicAction) => dynamicAction.id === fileEntry.note_id);
                            const noteLabel = matchedAction?.label || '';
    
                            return (
                                <div className="text-box mb-4 full-width" key={`teacher-notes-${groupIndex}`}>
                                    <div className="header">
                                        <h3>{noteLabel}</h3>
                                        <div className="actions">
                                            <button
                                                className={`mark-demo ${fileEntry.for_parents === 'yes' ? 'active-demo' : 'inactive-demo'}`}
                                                onClick={() => handleInputTextBoxNew(fileEntry.note, parentIndex, i, subIndex, matchedAction?.id, fileEntry.note_id, false, true)}
                                                disabled={!fileEntry.note}
                                            >
                                                <span>✔</span> For Parents
                                            </button>
                                            <button
                                                className={`mark-demo ${fileEntry.mark_demo === 'yes' ? 'active-demo' : 'inactive-demo'}`}
                                                onClick={() => handleInputTextBoxNew(fileEntry.note, parentIndex, i, subIndex, matchedAction?.id, fileEntry.note_id, true, false)}
                                                disabled={!fileEntry.note}
                                            >
                                                <span>✔</span> Mark As Demo
                                            </button>
                                            <button
                                                className="close-btn"
                                                onClick={() => handleCrossBtn(fileEntry.note_id, parentIndex, i, subIndex)}
                                            >
                                                <XCircle />
                                            </button>
                                        </div>
                                    </div>
                                    <TextEditor
                                        value={fileEntry.note || ''}
                                        onChange={(content) => handleInputTextBoxNew(content, parentIndex, i, subIndex, matchedAction?.id, fileEntry.note_id)}
                                        placeholder={noteLabel}
                                        height={"235px"}
                                    />
                                </div>
                            );
                        }
                    })}
                </div>
            );
        });
    };
    


    const handleCrossBtn = (noteId, parentIndex, topicIndex, subTopicIndex) => {
        const updatedData = newDemoData.map((subject, pIdx) => {
            if (pIdx === parentIndex) {
                return {
                    ...subject,
                    topics: subject?.topics?.map((topic, tIdx) => {
                        if (tIdx === topicIndex) {
                            return {
                                ...topic,
                                sub_topics: topic.sub_topics.map((subTopic, sIdx) => {
                                    if (sIdx === subTopicIndex) {
                                        const updatedFileData = subTopic.file_data.filter(
                                            (file) => file.note_id !== noteId // Remove the file with the matching note_id
                                        );

                                        return {
                                            ...subTopic,
                                            file_data: updatedFileData  // Return updated file_data
                                        };
                                    }
                                    return subTopic;
                                })
                            };
                        }
                        return topic;
                    })
                };
            }
            return subject;
        });

        setNewDemoData(updatedData);  // Update the state
    };

    const headers = {
        'Content-Type': 'application/json', // Set the content type based on your API requirements
        'Authentication_key': authentication_key, // Include any authorization token if needed
        'Authorization': 'Bearer ' + authentication_key,
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseUrl}/partner/curriculum-skill-table-data`, { headers });
                if (response.data.status === 'success') {
                    const formattedOptions = response.data.data.map(item => ({
                        value: item.id,
                        label: item.name
                    }));
                    setOptions(formattedOptions);
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                toast.error(error);
            }
        };
        const fetchDataAreaOfLearning = async () => {
            try {
                const response = await axios.get(`${baseUrl}/partner/curriculum-learning-dropdown`, { headers });
                if (response.data.status === 'success') {
                    // const formattedOptions = response.data.data.map(item => ({
                    //     value: item.id,
                    //     label: item.name
                    // }));
                    setAreaOfLearning(response.data.data);
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                toast.error(error);
            }
        };
        const fetchActions = async () => {
            try {
                const response = await axios.get(`${baseUrl}/partner/curriculum-note-dropdown`, { headers });
                if (response.data.status === 'success') {
                    setActionDropdown(response.data.data);
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                toast.error(error);
            }
        };
        fetchActions();
        fetchData();
        fetchDataAreaOfLearning();
    }, []);

    const handleChange = (selectedOptions, parentIndex, topicIndex, subTopicIndex) => {
        const updatedData = newDemoData.map((subject, pIdx) => {
            if (pIdx === parentIndex) {
                return {
                    ...subject,
                    topics: subject.topics.map((topic, tIdx) => {
                        if (tIdx === topicIndex) {
                            return {
                                ...topic,
                                sub_topics: topic.sub_topics.map((subTopic, sIdx) => {
                                    if (sIdx === subTopicIndex) {
                                        return {
                                            ...subTopic,
                                            // Store an array of selected skill values
                                            skill_data: selectedOptions ? selectedOptions.map(option => option.value) : []
                                        };
                                    }
                                    return subTopic;
                                })
                            };
                        }
                        return topic;
                    })
                };
            }
            return subject;
        });

        setNewDemoData(updatedData);  // Update state
    };
    // const handleChangeInAreaOfLearning = (selectedOptions, parentIndex, topicIndex, subTopicIndex) => {
    //     const updatedData = newDemoData.map((subject, pIdx) => {
    //         if (pIdx === parentIndex) {
    //             return {
    //                 ...subject,
    //                 topics: subject.topics.map((topic, tIdx) => {
    //                     if (tIdx === topicIndex) {
    //                         return {
    //                             ...topic,
    //                             sub_topics: topic.sub_topics.map((subTopic, sIdx) => {
    //                                 if (sIdx === subTopicIndex) {
    //                                     return {
    //                                         ...subTopic,
    //                                         // Store an array of selected skill values
    //                                         learning_data: selectedOptions ? selectedOptions.map(option => option.value) : []
    //                                     };
    //                                 }
    //                                 return subTopic;
    //                             })
    //                         };
    //                     }
    //                     return topic;
    //                 })
    //             };
    //         }
    //         return subject;
    //     });

    //     setNewDemoData(updatedData);  // Update state
    // };

    const handleChangeInMinutes = (selectedOption, parentIndex, topicIndex, subTopicIndex) => {
        // console.log(selectedOption)
        const updatedData = newDemoData.map((subject, pIdx) => {
            if (pIdx === parentIndex) {
                return {
                    ...subject,
                    topics: subject.topics.map((topic, tIdx) => {
                        if (tIdx === topicIndex) {
                            return {
                                ...topic,
                                sub_topics: topic.sub_topics.map((subTopic, sIdx) => {
                                    if (sIdx === subTopicIndex) {
                                        return {
                                            ...subTopic,
                                            mins: selectedOption ? selectedOption.value : "" // Set the skill name (label) instead of the id
                                        };
                                    }
                                    return subTopic;
                                })
                            };
                        }
                        return topic;
                    })
                };
            }
            return subject;
        });

        setNewDemoData(updatedData); // Update state
    };


    const initializeActiveActions = () => {
        const initialActions = {};

        newDemoData.forEach((subject, parentIndex) => {
            subject.topics.forEach((topic, topicIndex) => {
                topic.sub_topics.forEach((subTopic, subTopicIndex) => {
                    const actions = [];

                    // Iterate over each file in file_data and add actions in the order they appear
                    subTopic.file_data.forEach(file => {
                        if (file.note_id) {
                            actions.push(file.note_id);
                        }
                        if (file.file_id) {
                            actions.push('258');
                        }
                    });

                    // Only add if there are actions present
                    if (actions.length > 0) {
                        if (!initialActions[parentIndex]) initialActions[parentIndex] = {};
                        if (!initialActions[parentIndex][topicIndex]) initialActions[parentIndex][topicIndex] = {};
                        initialActions[parentIndex][topicIndex][subTopicIndex] = actions;
                    }
                });
            });
        });

        setActiveActions(initialActions);
    };



    useEffect(() => {
        if (editId) {
            initializeActiveActions();
        }
    }, [newDemoData, editId]);


    const hasData = (parentIndex, topicIndex, subTopicIndex, field) => {
        return newDemoData[parentIndex]?.topics[topicIndex]?.sub_topics[subTopicIndex]?.file_data.find(file => file[field] !== "") !== undefined;
    };

    const isActive = (action, parentIndex, topicIndex, subTopicIndex) => {
        const actions = activeActions[parentIndex]?.[topicIndex]?.[subTopicIndex];
        // console.log("active actions", actions)
        return Array.isArray(actions) ? actions.includes(String(action)) : false;  // Safely check if actions is an array
    };

    useEffect(() => {
        // Initialize the selectedType for each topic and subtopic
        setSelectedType((prevSelectedType) => {
            const updatedSelectedType = { ...prevSelectedType }; // Preserve previous state

            newDemoData.forEach((subject, subjectIndex) => {
                subject.topics.forEach((topic, topicIndex) => {
                    // Set for topic if not already present
                    const topicKey = `${subjectIndex}-${topicIndex}`;
                    if (!updatedSelectedType[topicKey]) {
                        updatedSelectedType[topicKey] = "Sub Topic";
                    }

                    // Set for each sub_topic if not already present
                    topic.sub_topics.forEach((subTopic, subTopicIndex) => {
                        const subTopicKey = `${subjectIndex}-${topicIndex}-${subTopicIndex}`;
                        if (!updatedSelectedType[subTopicKey]) {
                            updatedSelectedType[subTopicKey] = "Sub Topic";
                        }
                    });
                });
            });

            return updatedSelectedType;
        });
    }, [newDemoData]);  // This will run whenever newDemoData changes


    const handleSelectChange = (e, parentIndex, i, subIndex) => {
        const value = e.target?.value
        const key = `${parentIndex}-${i}-${subIndex}`;

        // Update the state with the new selected value
        setSelectedType(prevState => ({
            ...prevState,
            [key]: value
        }));
    };


    const getPlaceholder = (parentIndex, i, subIndex) => {
        const key = `${parentIndex}-${i}-${subIndex}`;
        return selectedType[key] === 'Sub Topic' ? 'Enter Sub Topic' : 'Add Activity';
    };

    // Handle toggle in subject tab visibility
    const toggleVisibility = (parentIndex) => {
        setVisibleTopics(prev => ({
            ...prev,
            [parentIndex]: !prev[parentIndex] // Toggle the visibility
        }));
    };
    // console.log(visibleTopics)
    // Subject curriclum preview code toggle
    const handleSubjectClick = (index) => {
        if (activeSubjects.includes(index)) {
            setActiveSubjects(activeSubjects.filter(i => i !== index)); // Collapse the subject
        } else {
            setActiveSubjects([...activeSubjects, index]); // Expand the subject
        }
    };

    // topic curriclum preview code toggle
    const handleTopicClick = (subjectIndex, topicIndex) => {
        const activeTopicsCopy = { ...activeTopics };
        if (!activeTopicsCopy[subjectIndex]) {
            activeTopicsCopy[subjectIndex] = [];
        }

        if (activeTopicsCopy[subjectIndex].includes(topicIndex)) {
            activeTopicsCopy[subjectIndex] = activeTopicsCopy[subjectIndex].filter(i => i !== topicIndex); // Collapse the topic
        } else {
            activeTopicsCopy[subjectIndex].push(topicIndex); // Expand the topic
        }

        setActiveTopics(activeTopicsCopy);
    };

    //HandleShow Add Image
    const handleTopicWiseImage = (arr) => {
        const cloneData = [...newDemoData];
        if (showTopicImg.length === 3) {
            const [subjectIndex, topicIndex, subTopicIndex] = showTopicImg;
            const existingFiles = cloneData[subjectIndex].topics[topicIndex].sub_topics[subTopicIndex].file_data || [];

            const newFiles = arr.map(file => ({
                file_id: file.file_id,
                note_id: "258",
                note: "",
                mark_demo: "no",
                for_download: "no",
                file_name: file.name,
                file_type: file.file_type,
                file_path: file.file_path,
            }));

            // Merge new files with existing file data without overwriting existing fields
            cloneData[subjectIndex].topics[topicIndex].sub_topics[subTopicIndex].file_data = [...existingFiles, ...newFiles];
        }

        setNewDemoData(cloneData);
        setIsFileModal(!isFileModal);
    };

    const handleTopicFileData = (arr) => {
        // console.log("arr", arr)
        // console.log("showTopicImg", showTopicImg)
        const cloneData = [...newDemoData];
        if (showTopicImg.length === 2) {
            const [subjectIndex, topicIndex] = showTopicImg;
            const existingFiles = cloneData[subjectIndex].topics[topicIndex].file_data || [];

            const newFiles = arr.map(file => ({
                file_id: file.file_id,
                note_id: "258",
                note: "",
                mark_demo: "no",
                file_name: file.name,
                file_type: file.file_type,
                file_path: file.file_path,
            }));

            // Merge new files with existing file data without overwriting existing fields
            cloneData[subjectIndex].topics[topicIndex].file_data = [...existingFiles, ...newFiles];
        }

        setNewDemoData(cloneData);
        setTopicFileData(!topicFileData);
        setOpenView(false)
    };
    // console.log(showTopicImg)
    // Handle to get Class Dropdown
    const handleGetClassDropdown = () => {
        axios.get(`${baseUrl}/partner/class-dropdown`, {
            headers,
        })
            .then((res) => {
                const response = res.data;
                if (response.status === "success") {
                    const data = response.data;
                    setClassDropdown(data)
                } else {
                    console.log(response.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleGetCategoryDropdown = () => {
        axios.get(`${baseUrl}/partner/curriculum-category-dropdown`, {
            headers,
        })
            .then((res) => {
                const response = res.data;
                if (response.status === "success") {
                    const data = response.data;
                    setCategoryDropdown(data)
                } else {
                    console.log(response.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleUpdate = (draftId) => {
        setLoad(true)
        axios.postForm(`${baseUrl}/partner/curriculum-update`, {
            title,
            description,
            category_id: selectCate,
            curriculum_type: content_status ? "1" : "2",
            status: draftId ? draftId : draftStatus,
            content_data: JSON.stringify(newDemoData),
            class_id: classId,
            auth_bearer_token: authentication_key,
            id: editId
        }, {
            headers: {
                'Content-Type': 'application/json', // Set the content type based on your API requirements
                'authentication_key': authentication_key, // Include any authorization token if needed
                'Authorization': 'Bearer ' + authentication_key,
                // Add other headers as needed
            }
        }).then(res => {
            const data = res.data
            if (data.status === "success") {
                toast.success("Successfully uploaded")
                navigation("/modules/curriculum")
                handleReset()
            } else {
                toast.error(data.message)
            }
            setLoad(false)
        }
        ).catch(err => {
            console.log(err)
            setLoad(false)
        })
    }
    const handleAddCurriculum = (draftId) => {
        setLoad(true)
        axios.postForm(`${baseUrl}/partner/curriculum-store`, {
            title,
            description,
            category_id: selectCate,
            curriculum_type: content_status ? "1" : "2",
            status: draftId ? draftId : draftStatus,
            class_id: classId,
            content_data: JSON.stringify(newDemoData),
            auth_bearer_token: authentication_key
        }, {
            headers: {
                'Content-Type': 'application/json', // Set the content type based on your API requirements
                'authentication_key': authentication_key, // Include any authorization token if needed
                'Authorization': 'Bearer ' + authentication_key,
                // Add other headers as needed
            }
        }).then(res => {
            const data = res.data
            if (data.status === "success") {
                toast.success("Successfully uploaded")
                navigation("/modules/curriculum")
                handleReset()
            } else {
                toast.error(data.message)
            }
            setLoad(false)
        }
        ).catch(err => {
            console.log(err)
            setLoad(false)
        })
    }
    const handleCheck = () => {
        let checked = true
        const contentData = [...newDemoData]
        contentData?.forEach(element => {
            if (element.name?.length > 0) {
                element.topics?.forEach(io => {
                    if (!io.name?.length > 0 && checked) {
                        toast.error("Please fill the topic name")
                        checked = false
                    }
                })
            } else if (checked) {
                toast.error("Please fill the part name")
                checked = false
            }
        })
        return checked
    }
    const handleSubmit = (draftId) => {
        if (handleCheck()) {
            if (editId) {
                handleUpdate(draftId)
            } else {
                handleAddCurriculum(draftId)
            }
        }
    }

    const handleAddSubject = (parentIndex, index, type) => {
        // const newData = [{ value: "", list: [] }]
        if (type === "sub_topices") {
            const newData = newDemoData?.map((io, i) => {
                if (i === parentIndex) {
                    io["topics"] = io.topics?.map((elm, ind) => {
                        if (ind === index) {
                            elm["sub_topics"] = editId ? [...elm.sub_topics, { name: "", id: 0, topic_days: 0, topic_grace: 0, file_data: [] }] : [...elm.sub_topics, { name: "", topic_days: 0, topic_grace: 0, file_data: [] }]
                        }
                        return elm
                    })
                }
                return io
            })
            setNewDemoData(newData)
        } else {
            const data = newDemoData?.map((io, i) => {
                if (i === parentIndex) {
                    io["topics"] = editId ? [...io.topics, { name: "", id: 0, topic_days: 0, topic_grace: 0, file_data: [], sub_topics: [{ name: "", id: 0, topic_days: 0, topic_grace: 0, file_data: [] }] }] : [...io.topics, { name: "", topic_days: 0, topic_grace: 0, file_data: [], sub_topics: [{ name: "", sub_topic_type: 2, topic_days: 0, topic_grace: 0, file_data: [] }] }]
                }
                return io
            })
            setNewDemoData(data)
        }
    }

    const handleRemoveSubject = (parentIndex, index, type, sub_index) => {
        if (type === "sub-subject") {
            const newData = newDemoData?.map((io, i) => {
                if (parentIndex === i) {
                    if (io.topics?.length > 1) {
                        io["topics"] = io.topics?.filter((io, subIndex) => subIndex !== index)
                    }
                }
                return io
            })
            setNewDemoData(newData)
        } else if (type === "subject") {
            const data = newDemoData?.filter((io, i) => parentIndex !== i)
            setNewDemoData(data)
        } else if (type === "sub-sub_topices") {
            const subData = newDemoData?.map((io, i) => {
                if (parentIndex === i) {
                    io["topics"] = io.topics?.map((ele, subIndex) => {
                        if (index === subIndex) {
                            if (ele.sub_topics?.length > 1) {
                                ele["sub_topics"] = ele.sub_topics?.filter((io, Ind) => Ind !== sub_index)
                            }
                        }
                        return ele
                    })
                }
                return io
            })
            setNewDemoData(subData)
        }
        setShowTopicImg([])
    }
    ///Handle INput 
    const handleInputTextBox = (value, parentIndex, subIndex, type) => {
        if (type === "subject") {
            const newSubjData = newDemoData?.map((io, i) => {
                if (parentIndex === i) {
                    io["name"] = value
                }
                return io
            })
            // console.log(newSubjData, "newSubjData")
            setNewDemoData(newSubjData)
        } else if (type === "sub-subject") {
            const newSubData = newDemoData?.map((io, index) => {
                if (parentIndex === index) {
                    io["topics"] = io.topics?.map((elm, i) => {
                        if (subIndex === i) {
                            elm["name"] = value
                        }
                        return elm
                    })
                }
                return io
            })
            setNewDemoData(newSubData)
        } else if (type === "sub-sub-subject") {
            const newSubData = newDemoData?.map((io, index) => {
                if (parentIndex === index) {
                    io["topics"] = io.topics?.map((elm, i) => {
                        if (subIndex === i) {
                            elm["sub_topics"] = elm.sub_topics?.map((element, sub_index) => {
                                if (showTopicImg[2] === sub_index) {
                                    element["name"] = value
                                }
                                return element
                            })
                        }
                        return elm
                    })
                }
                return io
            })
            setNewDemoData(newSubData)
        } else if (type === "description") {
            const newDescData = newDemoData?.map((io, index) => {
                if (parentIndex === index) {
                    io["topics"] = io.topics?.map((elm, i) => {
                        if (subIndex === i) {
                            elm.sub_topics = elm.sub_topics?.map((element, sub_index) => {
                                if (showTopicImg[2] === sub_index) {
                                    element["description"] = value; // Update description
                                }
                                return element;
                            });
                        }
                        return elm;
                    });
                }
                return io;
            });
            setNewDemoData(newDescData);
        } else if (type === "prerequisites") {
            const newPrereqData = newDemoData?.map((io, index) => {
                if (parentIndex === index) {
                    io["topics"] = io.topics?.map((elm, i) => {
                        if (subIndex === i) {
                            elm.sub_topics = elm.sub_topics?.map((element, sub_index) => {
                                if (showTopicImg[2] === sub_index) {
                                    element["prerequisites"] = value; // Update prerequisites
                                }
                                return element;
                            });
                        }
                        return elm;
                    });
                }
                return io;
            });
            setNewDemoData(newPrereqData);
        }
        else if (type === "conclusion") {
            const newPrereqData = newDemoData?.map((io, index) => {
                if (parentIndex === index) {
                    io["topics"] = io.topics?.map((elm, i) => {
                        if (subIndex === i) {
                            elm.sub_topics = elm.sub_topics?.map((element, sub_index) => {
                                if (showTopicImg[2] === sub_index) {
                                    element["conclusion"] = value; // Update conclusion
                                }
                                return element;
                            });
                        }
                        return elm;
                    });
                }
                return io;
            });
            setNewDemoData(newPrereqData);
        }
    }
    const handleInputTextBoxNew = (value, parentIndex, topicIndex, subTopicIndex, fieldType, actionType, markAsDemo = false, forParents = false) => {
        // console.log("handleinputtext", value, parentIndex, topicIndex, subTopicIndex, fieldType, actionType, markAsDemo)
        const updatedData = newDemoData.map((subject, pIdx) => {
            if (pIdx === parentIndex) {
                return {
                    ...subject,
                    topics: subject.topics.map((topic, tIdx) => {
                        if (tIdx === topicIndex) {
                            return {
                                ...topic,
                                sub_topics: topic.sub_topics.map((subTopic, sIdx) => {
                                    if (sIdx === subTopicIndex) {
                                        // Find the existing entry by note_id (actionType)
                                        let existingEntryIndex = subTopic.file_data.findIndex(file => file.note_id === actionType);

                                        if (existingEntryIndex > -1) {
                                            // Entry exists: update or remove it
                                            const existingEntry = subTopic.file_data[existingEntryIndex];

                                            if (value === '') {
                                                // If value is empty, remove the entry
                                                subTopic.file_data.splice(existingEntryIndex, 1);
                                            } else {
                                                // Otherwise, update the note and dynamic fieldType value
                                                // existingEntry[fieldType] = value;
                                                existingEntry.note = value;  // Update the note field

                                                // Handle mark_demo toggle
                                                if (markAsDemo) {
                                                    existingEntry.mark_demo = existingEntry.mark_demo === "yes" ? "no" : "yes";
                                                }
                                                if (forParents) {
                                                    existingEntry.for_parents = existingEntry.for_parents === "yes" ? "no" : "yes";
                                                }
                                            }
                                        } else {
                                            // No existing entry: create a new one
                                            const newEntry = {
                                                mark_demo: "no",  // Set demo status based on markAsDemo
                                                note_id: actionType,  // Set actionType as note_id
                                                note: value || "",    // Set initial note value
                                                for_parents: "no",
                                            };
                                            subTopic.file_data.push(newEntry);  // Add the new entry to file_data
                                        }

                                        return {
                                            ...subTopic,
                                            file_data: [...subTopic.file_data]  // Keep the file_data array updated
                                        };
                                    }
                                    return subTopic;
                                })
                            };
                        }
                        return topic;
                    })
                };
            }
            return subject;
        });

        setNewDemoData(updatedData);  // Update the state
    };

    const handleAddNewPart = () => {
        if (editId) {
            setNewDemoData([...newDemoData, { name: "", id: 0, topics: [{ name: "", topic_days: 0, topic_grace: 0, file_data: [], sub_topics: [{ name: "", topic_days: 0, topic_grace: 0, file_data: [] }] }] }])
        } else {
            setNewDemoData([...newDemoData, { name: "", topics: [{ name: "", topic_days: 0, topic_grace: 0, file_data: [], sub_topics: [{ name: "", topic_days: 0, topic_grace: 0, file_data: [] }] }] }])
        }
    }
    const handleDeleteTopic = (id, pInd, index, type) => {
        setLoad(true)
        axios.postForm(`${baseUrl}/partner/curriculum-topic-destroy`, {
            id,
            curriculum_id: editId,
            auth_bearer_token: authentication_key
        }, {
            headers: {
                'Content-Type': 'application/json', // Set the content type based on your API requirements
                'authentication_key': authentication_key, // Include any authorization token if needed
                'Authorization': 'Bearer ' + authentication_key,
                // Add other headers as needed
            }
        }).then(res => {
            const data = res.data
            if (data.status === "success") {
                // setFlag(!flag)
                handleRemoveSubject(pInd, index, type)
            } else {
                toast.error(data.message)
            }
            setLoad(false)
        }
        ).catch(err => {
            console.log(err)
            setLoad(false)
        })
    }

    const handleDeleteSubTopic = (id, pIndex, tIndex, type, sTIndex) => {
        setLoad(true)
        axios.postForm(`${baseUrl}/partner/curriculum-subtopic-destroy`, {
            id,
            curriculum_id: editId,
            auth_bearer_token: authentication_key
        }, {
            headers: {
                'Content-Type': 'application/json', // Set the content type based on your API requirements
                'authentication_key': authentication_key, // Include any authorization token if needed
                'Authorization': 'Bearer ' + authentication_key,
                // Add other headers as needed
            }
        }).then(res => {
            const data = res.data
            if (data.status === "success") {
                handleRemoveSubject(pIndex, tIndex, type, sTIndex)
            } else {
                toast.error(data.message)
            }
            setLoad(false)
        }
        ).catch(err => {
            console.log(err)
            setLoad(false)
        })
    }

    const handleDeleteFile = (id) => {
        setLoad(true)
        axios.postForm(`${baseUrl}/partner/curriculum-content-file-destroy`, {
            id,
            curriculum_id: editId,
            auth_bearer_token: authentication_key
        }, {
            headers: {
                'Content-Type': 'application/json', // Set the content type based on your API requirements
                'authentication_key': authentication_key, // Include any authorization token if needed
                'Authorization': 'Bearer ' + authentication_key,
                // Add other headers as needed
            }
        }).then(res => {
            const data = res.data
            if (data.status === "success") {
                setFlag(!flag)
            } else {
                toast.error(data.message)
            }
            setLoad(false)
        }
        ).catch(err => {
            setLoad(false)
            console.log(err)
        })
    }

    function handleDropdown(countryName) {
        setCountry(countryName)
    }

    function handleSubjectInput(e) {
        const newArray = [...subjectArray];
        newArray.push("");
        setSubjectArray(newArray);
    }

    function handleCurriculamState() {
        if (newCurriculamState === 'basic-details') {
            if (isActiveNext) {
                setNewCurriculamState('content')
            }
        } else if (newCurriculamState === 'content') {
            setNewCurriculamState('basic-details')
        }
    }

    function handleFiles(e) {
        setAddFiles(!addFiles)
    }
    const removeContentCard = (index, type) => {
        if (showTopicImg?.length >= 2) {
            const data = newDemoData?.map((io, i) => {
                if (i === showTopicImg[0]) {
                    io.topics?.map((elm, ind) => {
                        if (type === "sub_topic" && ind === showTopicImg[1]) {
                            elm["sub_topics"] = elm.sub_topics.map((element, subIndex) => {
                                if (subIndex === showTopicImg[2]) {
                                    element["file_data"] = element.file_data?.filter((element, indx) => indx !== index)
                                }
                                return element
                            })
                        } else {
                            if (ind === showTopicImg[1]) {
                                elm["file_data"] = elm.file_data?.filter((element, indx) => indx !== index)
                            }
                        }
                        return elm
                    })
                }
                return io
            })
            setNewDemoData(data)
        }
    }

    const handleDemoContent = (index, type) => {
        const builkCheck = newDemoData[showTopicImg[0]].topics[showTopicImg[1]].file_data?.filter(io => io.mark_demo === "yes")?.length === newDemoData[showTopicImg[0]].topics[showTopicImg[1]].file_data?.length
        if (showTopicImg?.length === 2) {
            const data = newDemoData?.map((io, i) => {
                if (i === showTopicImg[0]) {
                    io.topics?.map((elm, ind) => {
                        if (ind === showTopicImg[1]) {
                            elm["file_data"] = elm.file_data?.map((element, indx) => {
                                if (type === "bulk") {
                                    element["mark_demo"] = builkCheck ? "no" : "yes"
                                } else if (indx === index) {
                                    element["mark_demo"] = element.mark_demo === "no" ? "yes" : "no"
                                }
                                return element
                            })
                        }
                        return elm
                    })
                }
                return io
            })
            setNewDemoData(data)
        }
    }
    const handleDemoContentSubTopic = (parentIndex, topicIndex, subTopicIndex, index, type) => {

        const fileData = newDemoData?.[parentIndex]?.topics?.[topicIndex]?.sub_topics?.[subTopicIndex]?.file_data;

        if (!fileData) {
            console.error('No file data found for the selected sub-topic');
            return;
        }

        // Check if all file_data items are marked as demo
        const allMarkedAsDemo = fileData.every(file => file.mark_demo === "yes");

        // Update the file_data based on the type ('bulk' or specific file)
        const updatedDemoData = newDemoData.map((subject, i) => {
            if (i === parentIndex) {
                return {
                    ...subject,
                    topics: subject.topics.map((topic, tIndex) => {
                        if (tIndex === topicIndex) {
                            return {
                                ...topic,
                                sub_topics: topic.sub_topics.map((subTopic, sIndex) => {
                                    if (sIndex === subTopicIndex) {
                                        return {
                                            ...subTopic,
                                            file_data: subTopic.file_data.map((file, fIndex) => {
                                                if (type === "bulk") {
                                                    // Bulk toggle: mark all files as 'yes' or 'no'
                                                    return { ...file, mark_demo: allMarkedAsDemo ? "no" : "yes" };
                                                } else if (fIndex === index && type === "markAsDemo") {
                                                    // Toggle individual file mark_demo
                                                    return { ...file, mark_demo: file.mark_demo === "yes" ? "no" : "yes" };
                                                } else if (fIndex === index && type === "downloadable") {
                                                    // Toggle individual file for_download
                                                    return { ...file, for_download: file.for_download === "yes" ? "no" : "yes" };
                                                }
                                                return file;
                                            })
                                        };
                                    }
                                    return subTopic;
                                })
                            };
                        }
                        return topic;
                    })
                };
            }
            return subject;
        });

        setNewDemoData(updatedDemoData);
    };

    const handleGetEditData = (id) => {
        setLoad(true)
        const payload = {
            id,
            auth_bearer_token: authentication_key
        }
        axios.get(`${baseUrl}/partner/curriculum-edit`, {
            headers,
            params: payload
        }).then(res => {
            const response = res.data
            if (response.status === "success") {
                const data = response.data
                if (data) {
                    setTitle(data.title)
                    setDescription(data.description)
                    setSelectCate(data.category_id)
                    setClassId(data.class_id)
                    setContent_status(data.curriculum_type === "1")
                    if (data.content_data) {
                        const content_data = data.content_data.map(sub => {
                            if (sub.topics && Array.isArray(sub.topics) && sub.topics.length > 0) {
                                sub["topics"] = sub.topics.map(topic => {
                                    if (!topic.sub_topics || !Array.isArray(topic.sub_topics) || topic.sub_topics.length === 0) {
                                        topic["sub_topics"] = [{ name: "", id: 0, topic_days: 0, topic_grace: 0, file_data: [] }]
                                    }
                                    return topic
                                })
                            } else {
                                sub["topics"] = [{ name: "", id: 0, topic_days: 0, topic_grace: 0, file_data: [], sub_topics: [{ name: "", id: 0, topic_days: 0, topic_grace: 0, file_data: [] }] }]
                            }
                            return sub
                        })
                        setNewDemoData(content_data)
                    }
                }
                setLoad(false)
            } else {
                console.log(response.message)
                setLoad(false)
            }
        }
        ).catch(err => {
            console.log(err)
            setLoad(false)
        })

    }
    const handleDeleteTopicFileData = (parentIndex, topicIndex) => {
        const updatedData = newDemoData.map((subject, pIdx) => {
            if (pIdx === parentIndex) {
                return {
                    ...subject,
                    topics: subject.topics.map((topic, tIdx) => {
                        if (tIdx === topicIndex) {
                            return {
                                ...topic,
                                file_data: []  // Clear file_data to delete the file
                            };
                        }
                        return topic;
                    })
                };
            }
            return subject;
        });

        setNewDemoData(updatedData);  // Update the state with the deleted file
    };


    const handleSubTopicClick = (parentIndex, topicIndex, subTopicIndex) => {
        setActiveSubTopic(`${parentIndex}-${topicIndex}-${subTopicIndex}`);
    };

    const handleIncrese = (type, parentIndex, topicIndex, subIndex) => {
        const updatedData = newDemoData.map((subject, pIdx) => {
            if (pIdx === parentIndex) {
                return {
                    ...subject,
                    topics: subject.topics.map((topic, tIdx) => {
                        if (tIdx === topicIndex) {
                            // For topic level days increment
                            if (type === "topic") {
                                return {
                                    ...topic,
                                    topic_days: Number(topic.topic_days || 0) + 1
                                };
                            }
                            // For sub-topic level days increment
                            else if (type === "sub_topic") {
                                return {
                                    ...topic,
                                    sub_topics: topic.sub_topics.map((subTopic, sIdx) => {
                                        if (sIdx === subIndex) {
                                            return {
                                                ...subTopic,
                                                topic_days: Number(subTopic.topic_days || 0) + 1
                                            };
                                        }
                                        return subTopic;
                                    })
                                };
                            }
                        }
                        return topic;
                    })
                };
            }
            return subject;
        });
        setNewDemoData(updatedData);
    };
    const mapSkillData = (skillData) => {
        return skillData.map(skill =>
            typeof skill === "object" ? parseInt(skill.skill_id) : parseInt(skill)
        );
    };

    const handleChangeInAreaOfLearning = (selectedOptions, parentIndex, i, subIndex) => {
        const updatedLearningData = selectedOptions.map(option => ({
            subject_id: option.subject_id,
            subject_learning_data: [parseInt(option.value)]
        }));

        // Update the newDemoData state with the modified learning data for the specific sub-topic
        setNewDemoData(prevData => {
            const updatedData = [...prevData];
            updatedData[parentIndex].topics[i].sub_topics[subIndex].learning_data = updatedLearningData;
            return updatedData;
        });
    };
    const mapLearningData = (learningData) => {
        return learningData.map(item => ({
            subject_id: item.subject_id,
            subject_learning_data: (item.subject_learning_data || []).map(data =>
                typeof data === "object" ? parseInt(data.learning_id) : parseInt(data)
            )
        }));
    };

    const handleDecrease = (type, parentIndex, topicIndex, subIndex) => {
        const updatedData = newDemoData.map((subject, pIdx) => {
            if (pIdx === parentIndex) {
                return {
                    ...subject,
                    topics: subject.topics.map((topic, tIdx) => {
                        if (tIdx === topicIndex) {
                            // For topic level days decrement
                            if (type === "topic") {
                                return {
                                    ...topic,
                                    topic_days: Math.max((topic.topic_days || 0) - 1, 0)
                                };
                            }
                            // For sub-topic level days decrement
                            else if (type === "sub_topic") {
                                return {
                                    ...topic,
                                    sub_topics: topic.sub_topics.map((subTopic, sIdx) => {
                                        if (sIdx === subIndex) {
                                            return {
                                                ...subTopic,
                                                topic_days: Math.max((subTopic.topic_days || 0) - 1, 0)
                                            };
                                        }
                                        return subTopic;
                                    })
                                };
                            }
                        }
                        return topic;
                    })
                };
            }
            return subject;
        });
        setNewDemoData(updatedData);
    };
    const handleDaysInput = (value, field, type, parentIndex, topicIndex, subIndex) => {
        const updatedData = newDemoData.map((subject, pIdx) => {
            if (pIdx === parentIndex) {
                return {
                    ...subject,
                    topics: subject.topics.map((topic, tIdx) => {
                        if (tIdx === topicIndex) {
                            if (type === "topics") {
                                return {
                                    ...topic,
                                    [field]: value
                                };
                            } else if (type === "sub_topics") {
                                return {
                                    ...topic,
                                    sub_topics: topic.sub_topics.map((subTopic, sIdx) => {
                                        if (sIdx === subIndex) {
                                            return {
                                                ...subTopic,
                                                [field]: value
                                            };
                                        }
                                        return subTopic;
                                    })
                                };
                            }
                        }
                        return topic;
                    })
                };
            }
            return subject;
        });
        setNewDemoData(updatedData);
    };

    const handleToggleChange = (parentIndex, topicIndex, subTopicIndex, fieldName) => {
        const updatedData = [...newDemoData];
        const subTopic = updatedData[parentIndex]?.topics[topicIndex]?.sub_topics[subTopicIndex];

        if (subTopic) {
            // Ensure file_data exists as an array
            if (!Array.isArray(subTopic.file_data)) {
                subTopic.file_data = [];
            }

            // Check if the entry with the given field name exists in file_data
            const fieldIndex = subTopic.file_data.findIndex(file => file.hasOwnProperty(fieldName));

            if (fieldIndex === -1) {
                // Add the field (e.g., `{ diary_note: "1" }` or `{ gallery: "1" }`) if it doesn't exist
                subTopic.file_data.push({ [fieldName]: "1" });
            } else {
                // Remove the object with the specified field if toggle is deactivated
                subTopic.file_data.splice(fieldIndex, 1);
            }
        }

        setNewDemoData(updatedData);
    };

    const handleDeleteConfirmation = (id, parentIndex, topicIndex, subTopicType) => {
        confirmAlert({
            title: "Confirm delete",
            message: 'Do you really want to delete this topic?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        if (editId && id) {
                            handleDeleteTopic(id, parentIndex, topicIndex, subTopicType);
                        } else {
                            handleRemoveSubject(parentIndex, topicIndex, subTopicType);
                        }
                    },
                },
                {
                    label: 'No',
                    onClick: () => { },
                },
            ],
        });
    };

    const handleDeleteChapter = (id, pIndex) => {
        setLoad(true)
        axios.postForm(`${baseUrl}/partner/curriculum-chapter-destroy`, {
            id,
            curriculum_id: editId,
            auth_bearer_token: authentication_key
        }, {
            headers: {
                'Content-Type': 'application/json', // Set the content type based on your API requirements
                'authentication_key': authentication_key, // Include any authorization token if needed
                'Authorization': 'Bearer ' + authentication_key,
                // Add other headers as needed
            }
        }).then(res => {
            const data = res.data
            if (data.status === "success") {
                handleRemoveSubject(pIndex, null, "subject")
            } else {
                toast.error(data.message)
            }
            setLoad(false)
        }
        ).catch(err => {
            console.log(err)
            setLoad(false)
        })
    }

    const handleDeleteConfirmationChapter = (id, parentIndex) => {
        confirmAlert({
            title: "Confirm delete",
            message: 'Do you really want to delete this topic?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        if (editId && id) {
                            handleDeleteChapter(id, parentIndex);
                        } else {
                            handleRemoveSubject(parentIndex, null, "subject")
                        }
                    },
                },
                {
                    label: 'No',
                    onClick: () => { },
                },
            ],
        });
    };
    const handleReset = () => {
        if (editId) {
            handleGetEditData(editId)
        } else {
            setNewDemoData([{ name: "", topics: [{ name: "", topic_days: 0, topic_grace: 0, file_data: [], sub_topics: [{ name: "", topic_days: 0, topic_grace: 0, file_data: [] }] }] }])
            setTitle("")
            setSelectCate("")
            setDescription("")
        }
    }

    useEffect(() => {
        if (title && selectCate && !isActiveNext && classId) {
            setIsActiveNext(true)
        } else if ((!title || !selectCate || !classId) && isActiveNext) {
            setIsActiveNext(false)
        }
    }, [title, selectCate])
    useEffect(() => {
        const state = location.state
        if (state && state.id) {
            setEditId(state.id)
            handleGetEditData(state.id)
        } else {
            setEditId("")
        }
    }, [flag])
    useEffect(() => {
        handleGetClassDropdown()
        handleGetCategoryDropdown()
    }, [])
    const handleViewFile = (parentIndex, topicIndex) => {
        const fileData = newDemoData[parentIndex]?.topics[topicIndex]?.file_data[0];

        if (fileData) {
            setViewFileData(fileData);  // Set the file data to state for viewing
            setOpenView(!openView);     // Toggle the modal view
        }
    };

    return (
        <div>
            <div className='d-flex align-items-center gap justify-content-between p-2 mx-2' >
                <div className='d-flex align-items-center gap'>
                    <div onClick={(e) => handleStopBlocking(e)} style={{ cursor: "pointer" }} >
                        <img src={leftArrow} />
                    </div>
                    <div className='page-heading'>
                        {editId ? "Edit " : "Add "} Curriculum
                    </div>
                </div>
                {/* <ButtonComponent buttonType={'secondary'} title={'Take A Tour'} /> */}
            </div>

            <div className='m-4 '>
                <div className='d-flex main_curriculum_section' >
                    <div className='new-curriculam-first-part-section' >
                        <div>
                            <div className='d-flex basic-details-content-div'>
                                <span className='main_curriculum_tab' onClick={e => handleCurriculamState()} style={{ color: newCurriculamState === 'basic-details' ? '#0258a1' : '#C4C4C4', borderBottom: newCurriculamState === 'basic-details' ? '4px solid #0258a1' : '#C4C4C4', cursor: 'pointer' }} >Basic Details</span>
                                <span className='main_curriculum_tab' onClick={e => handleCurriculamState()} style={{ color: newCurriculamState === 'content' ? '#0258a1' : '#C4C4C4', borderBottom: newCurriculamState === 'content' ? '4px solid #0258a1' : '#C4C4C4', cursor: 'pointer' }}>Content</span>
                            </div>
                            {
                                newCurriculamState === 'basic-details' ? <div className='p-3 details-section d-flex flex-column gap'>
                                    {load ? (<>
                                        <SpinnerComponent />
                                    </>) : (<>
                                        <CustomInput
                                            placeholder={'Enter Title'}
                                            className={'w-100 curriclam-side-input-section'}
                                            onChange={(e) => setTitle(e.target.value)}
                                            value={title}
                                        />
                                        <textarea
                                            class="form-control"
                                            id="exampleFormControlTextarea1"
                                            rows="6"
                                            placeholder='Enter Description'
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                        />
                                        <DropDown
                                            options={classDropdown}
                                            placeholder="Select Class"
                                            value={classDropdown?.filter(elm => elm.id === classId)}
                                            onChange={(e) => setClassId(e && e.id ? e.id : "")}
                                            isClearable={true}
                                            id={'selectClass'}
                                        />
                                        <DropDown
                                            options={categoryDropdown}
                                            placeholder="Select Category"
                                            value={categoryDropdown?.filter(elm => elm.id === selectCate)}
                                            onChange={(e) => setSelectCate(e && e.id ? e.id : "")}
                                            isClearable={true}
                                            id={'selectCategory'}
                                        />
                                    </>)}
                                </div> : (<>
                                    <div>
                                        {load ? (<>
                                            <SpinnerComponent />
                                        </>) : (<>
                                            <div>
                                                {newDemoData?.length > 0 ? newDemoData?.map((element, parentIndex) => {
                                                    return (
                                                        <div className='main_add_subject_section' key={`subject-${parentIndex}`}>
                                                            <div className={`d-flex align-items-center ml-2 my-2 curriculam-side-subject-section curriculam-side-subject-cusInput chapter ${isInputFocused === `${parentIndex}subject-new-main` ? "curriculam-side-subject-cusInput-active" : ""}`}>
                                                                <div className='subject_counter'><strong>{parentIndex + 1}</strong></div>
                                                                <input
                                                                    placeholder={'Add Chapter'}
                                                                    className={'w-100'}
                                                                    onChange={(e) => handleInputTextBox(e.target.value, parentIndex, null, "subject")}
                                                                    value={element.name}
                                                                    onFocus={() => { setIsInputFocused(`${parentIndex}subject-new-main`); setShowTopicImg([]) }}

                                                                    onBlur={() => { setIsInputFocused(""); setShowTopicImg([]) }}
                                                                    onClick={() => toggleVisibility(parentIndex)}
                                                                />
                                                                {newDemoData?.length > 1 && <div className='trash2-icon' onClick={() => handleDeleteConfirmationChapter(element.id, parentIndex)}>
                                                                    <Trash2 style={{ cursor: 'pointer' }} />
                                                                </div>}
                                                            </div>
                                                            {visibleTopics[parentIndex] && element.topics?.map((elm, i) => (
                                                                <React.Fragment key={`topic-${parentIndex}-${i}`}>
                                                                    <div className='d-flex gap align-items-center'>
                                                                        <div className={`d-flex align-items-center mr-2 mb-2 ${parentIndex === 0 ? 'mt-2' : ""}`} style={{ border: "1px solid rgba(12, 12, 13, 0.1)", borderRadius: "4px", marginLeft: "8px", background: "white", width: "85%" }}>
                                                                            <div className='icon-menu-container' onClick={() => handleAddSubject(parentIndex, i)}>
                                                                                <Plus />
                                                                            </div>
                                                                            <div className={`d-flex align-items-center curriculam-side-subject-section curriculam-side-subject-cusInput ${showTopicImg?.length > 0 && showTopicImg[0] === parentIndex && showTopicImg[1] === i && isInputFocused === `${i}subject` ? "curriculam-side-subject-cusInput-active" : ""}`} style={{ flex: "1" }}>
                                                                                <div className="topic_counter"><strong>{parentIndex + 1}.{i + 1}</strong></div> {/* Topic Counter */}
                                                                                <input
                                                                                    placeholder={'Add Topic'}
                                                                                    className={'w-100'}
                                                                                    onChange={(e) => handleInputTextBox(e.target.value, parentIndex, i, "sub-subject")}
                                                                                    value={elm.name}
                                                                                    onFocus={() => { setIsInputFocused(`${i}subject`); setShowTopicImg([parentIndex, i]) }}
                                                                                />
                                                                                {element.topics.length > 1 && <div className='trash2-icon' onClick={() => handleDeleteConfirmation(elm.id, parentIndex, i, "sub-subject")}>
                                                                                    <Trash2 style={{ cursor: 'pointer' }} />
                                                                                </div>}
                                                                            </div>

                                                                        </div>
                                                                        <button onClick={() => { newDemoData[parentIndex]?.topics[i]?.file_data?.length === 1 ? handleViewFile(parentIndex, i) : setTopicFileData(!topicFileData); setHandBookUpload(true); setShowTopicImg([parentIndex, i]); }} style={{ height: "47px" }} className={`${newDemoData[parentIndex]?.topics[i]?.file_data?.length === 1 ? "topic-active" : "topic-upload"}`}>
                                                                            {newDemoData[parentIndex]?.topics[i]?.file_data?.length === 1 ? (
                                                                                <>
                                                                                    <span>View Handbook</span>
                                                                                    <ViewFilesTopic open={openView} setOpen={setOpenView} fileData={viewFileData} />

                                                                                    <X onClick={() => handleDeleteTopicFileData(parentIndex, i)} /> {/* Delete button */}

                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <Upload size={16} /> Upload Handbook
                                                                                </>
                                                                            )}
                                                                        </button>
                                                                    </div>
                                                                    {showTopicImg?.length === 2 && (
                                                                        <div className='add_topics_padding'>
                                                                            <div className='d-flex align-items-center flex-wrap'>
                                                                                {/* <CustomInputWithIncDec
                                                                                    title="Grace"
                                                                                    isPlusMinus={false}
                                                                                    value={newDemoData[showTopicImg[0]].topics[showTopicImg[1]].topic_grace ? newDemoData[showTopicImg[0]].topics[showTopicImg[1]].topic_grace : 0}
                                                                                    onChange={(e) => handleDaysInput(e.target.value)}
                                                                                /> */}
                                                                                <CustomInputWithIncDec
                                                                                    title="Days"
                                                                                    style={{ marginLeft: '5px' }}
                                                                                    value={newDemoData[parentIndex].topics[i].topic_days || 0}
                                                                                    onClickAdd={() => handleIncrese("topic", parentIndex, i)} // Pass type as "topic"
                                                                                    onClickMinus={() => handleDecrease("topic", parentIndex, i)} // Pass type as "topic"
                                                                                    onChange={(e) => handleDaysInput(e.target.value, "topic_days", "topics", parentIndex, i)} // Pass required indexes
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    {elm.sub_topics?.map((sub_elm, subIndex) => (
                                                                        <React.Fragment key={`sub-topic-${parentIndex}-${i}-${subIndex}`}>
                                                                            <div className={`d-flex align-items-center sub-topic-div mb-2 ${parentIndex === 0 ? 'mt-2' : ""}`} style={{ border: "1px solid #0C0C0D1A", borderRadius: "4px" }}>
                                                                                <div className='icon-menu-container' onClick={() => handleAddSubject(parentIndex, i, "sub_topices")}>
                                                                                    <Plus />
                                                                                </div>
                                                                                <div className={`d-flex align-items-center w-100 curriculam-side-subject-section curriculam-side-subject-cusInput ${showTopicImg?.length > 0 && showTopicImg[0] === parentIndex && showTopicImg[1] === i && showTopicImg[2] === subIndex ? "curriculam-side-subject-cusInput-active" : ""}`} style={{ borderRadius: "4px" }}>
                                                                                    <div className="sub_topic_counter"><strong>{parentIndex + 1}.{i + 1}.{subIndex + 1}</strong></div> {/* Sub-Topic Counter */}
                                                                                    <input
                                                                                        placeholder={getPlaceholder(parentIndex, i, subIndex)}
                                                                                        className={'w-100'}
                                                                                        onChange={(e) => handleInputTextBox(e.target.value, parentIndex, i, "sub-sub-subject")}
                                                                                        value={sub_elm.name}
                                                                                        onFocus={() => { setIsInputFocused(`${subIndex}sub_subject`); setShowTopicImg([parentIndex, i, subIndex]); handleSubTopicClick(parentIndex, i, subIndex); }}
                                                                                    />
                                                                                    <select className='choose_subtopic_activity' value={selectedType[`${parentIndex}-${i}-${subIndex}`] || 'Sub Topic'} onChange={(e) => handleSelectChange(e, parentIndex, i, subIndex)}>
                                                                                        <option value='Sub Topic'>Sub Topic</option>
                                                                                        <option value='Acitivity'>Activity</option>
                                                                                    </select>
                                                                                    <div className="add-action-container">
                                                                                        <button className="add-actions-btn" onClick={() => handleDropdownToggle(parentIndex, i, subIndex)}>
                                                                                            <Plus size={16} /> Add Actions
                                                                                        </button>
                                                                                        {dropdownVisible[`${parentIndex}-${i}-${subIndex}`] === true && (
                                                                                            <div className="dropdown-menu custom-dropdown" ref={dropdownRef}>
                                                                                                {actionsDrowdown.map((item) => {
                                                                                                    const IconComponent = handleIconNameChange(item.icon);
                                                                                                    return (
                                                                                                        <div
                                                                                                            key={item.id}
                                                                                                            className={`dropdown-item mb-1 ${isActive(item.id, parentIndex, i, subIndex) ? 'active' : ''}`}
                                                                                                            onClick={() => handleActionClick(item.id, parentIndex, i, subIndex)}
                                                                                                        >
                                                                                                            {IconComponent ? <IconComponent size={27} /> : <i className={`icon-${item.icon}`} />} {/* Render icon dynamically */}
                                                                                                            {item.label} {/* Dynamic label */}
                                                                                                        </div>
                                                                                                    )
                                                                                                })}
                                                                                                <div
                                                                                                    className={`dropdown-item ${isActive('258', parentIndex, i, subIndex) ? 'active' : ''}`}
                                                                                                    onClick={() => handleActionClick('258', parentIndex, i, subIndex)}
                                                                                                >
                                                                                                    <i className="icon-upload-files" />
                                                                                                    Upload Files
                                                                                                </div>

                                                                                            </div>
                                                                                        )}

                                                                                    </div>
                                                                                    {elm.sub_topics.length > 1 && <div className='trash2-icon' onClick={() => { editId && sub_elm.id ? handleDeleteSubTopic(sub_elm.id, parentIndex, i, "sub-sub_topices", subIndex) : handleRemoveSubject(parentIndex, i, "sub-sub_topices", subIndex) }}>
                                                                                        <Trash2 style={{ cursor: 'pointer' }} />
                                                                                    </div>}
                                                                                </div>
                                                                            </div>
                                                                            <div className='add_topics_items'>
                                                                                {activeSubTopic === `${parentIndex}-${i}-${subIndex}` && (

                                                                                    <div className='d-flex flex-column gap-3 mb-2'>

                                                                                        {/* Duration, Skills, and Area of Learning (Single Row) */}
                                                                                        <div className='d-flex gap-2 justify-content-between'>

                                                                                            {/* Duration Dropdown */}
                                                                                            <div className="dropdown-flex-20">
                                                                                                <Select
                                                                                                    className='multiselect_custom_css'
                                                                                                    options={duration.map(option => ({ value: option.value, label: `${option.label}` }))}
                                                                                                    isClearable={true}
                                                                                                    onChange={(selectedOption) => handleChangeInMinutes(selectedOption, parentIndex, i, subIndex)}
                                                                                                    placeholder="Duration"
                                                                                                    value={duration.find(option => option.value === newDemoData[parentIndex]?.topics[i]?.sub_topics[subIndex]?.mins) || ""}
                                                                                                />
                                                                                            </div>

                                                                                            {/* Skills Dropdown */}
                                                                                            <div className="dropdown-flex-33">
                                                                                                <Select
                                                                                                    className='multiselect_custom_css'
                                                                                                    options={options}
                                                                                                    isClearable={true}
                                                                                                    isMulti={true}
                                                                                                    onChange={(selectedOptions) => handleChange(selectedOptions, parentIndex, i, subIndex)}
                                                                                                    placeholder="Select skills"
                                                                                                    value={options.filter(option => {
                                                                                                        const normalizedSkillData = mapSkillData(newDemoData[parentIndex]?.topics[i]?.sub_topics[subIndex]?.skill_data || []);
                                                                                                        return normalizedSkillData.includes(parseInt(option.value));
                                                                                                    }) || []}
                                                                                                />
                                                                                            </div>

                                                                                            {/* Area of Learning Dropdown */}
                                                                                            <div className="dropdown-flex-33">
                                                                                                <Select
                                                                                                    className='multiselect_custom_css'
                                                                                                    options={areaOfLearning}
                                                                                                    isClearable={true}
                                                                                                    isMulti={true}
                                                                                                    onChange={(selectedOptions) => handleChangeInAreaOfLearning(selectedOptions, parentIndex, i, subIndex)}
                                                                                                    placeholder="Area of Learnings"
                                                                                                    value={areaOfLearning.filter(option => {
                                                                                                        const normalizedLearningData = mapLearningData(newDemoData[parentIndex]?.topics[i]?.sub_topics[subIndex]?.learning_data || []);
                                                                                                        return normalizedLearningData.some(area =>
                                                                                                            area.subject_id === option.subject_id &&
                                                                                                            area.subject_learning_data.includes(parseInt(option.value))
                                                                                                        );
                                                                                                    }) || []}
                                                                                                />
                                                                                            </div>

                                                                                        </div>

                                                                                        {/* Diary Note and Gallery Toggles (Second Row) */}
                                                                                        <div className='d-flex gap-2 align-items-center'>
                                                                                            <div className="toggle-div-style font-adjust">
                                                                                                <Toggle
                                                                                                    defaultChecked={newDemoData[parentIndex]?.topics[i]?.sub_topics[subIndex]?.diary_note === "1"}
                                                                                                    onChange={() => handleToggleChange(parentIndex, i, subIndex, 'diary_note')}
                                                                                                    icons={{
                                                                                                        checked: "Dairy Note",
                                                                                                        unchecked: "Diary Note"
                                                                                                    }}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="toggle-div-style font-adjust-gallery">
                                                                                                <Toggle
                                                                                                    defaultChecked={newDemoData[parentIndex]?.topics[i]?.sub_topics[subIndex]?.gallery === "1"}
                                                                                                    onChange={() => handleToggleChange(parentIndex, i, subIndex, 'gallery')}
                                                                                                    icons={{
                                                                                                        checked: "Gallery",
                                                                                                        unchecked: "Gallery"
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>

                                                                                )}
                                                                                <div className='editor-section1'>
                                                                                    <div className="">
                                                                                        {renderActiveActions(parentIndex, i, subIndex)}
                                                                                    </div>
                                                                                    {isActive('258', parentIndex, i, subIndex) &&
                                                                                        <>

                                                                                            <div className='d-flex gap-4 align-items-center mb-4'>
                                                                                                <ButtonComponent buttonType={'primary'} title={'Add Files'} onClick={e => setIsFileModal(!isFileModal)} />
                                                                                                <div className='media-link-icon-container' onClick={() => setMediaLinkModalOpen(true)}>
                                                                                                    <Link2 color='#838383' />
                                                                                                </div>
                                                                                            </div>
                                                                                        </>}

                                                                                </div>
                                                                            </div>
                                                                        </React.Fragment>))}
                                                                </React.Fragment>
                                                            ))}
                                                        </div>
                                                    );
                                                }) : null}
                                            </div>
                                        </>)}
                                        <div className='py-3 px-4'>
                                            <ButtonComponent buttonType={'primary'} title="Add New Section" onClick={e => handleAddNewPart()} />
                                        </div>
                                    </div>
                                </>)}
                        </div>
                        <div className='new-curriculam-sidebar-last'>
                            {
                                newCurriculamState === 'basic-details' ?
                                    (<>
                                        <div>
                                            <button style={{ backgroundColor: isActiveNext ? "#0258a1" : "#C4C4C4", border: isActiveNext ? "" : "none" }} onClick={e => handleCurriculamState()} type="button" className={`btn btn-primary w-100 py-2`}>Next {/*<IoMdArrowDropdown size={20} className='float-end' color='white' />*/}</button>
                                        </div>
                                    </>) : (<>
                                        <div className='d-flex justify-content-between align-items-end mb-4 gap'>
                                            <div className='d-flex flex-column' >
                                                <span className='private-public-span'>Private/Public</span>
                                                <span className='setcurriculam-span'>Set the curriculum public  </span>
                                            </div>
                                            <div><>
                                                <div className='toggle-div-style' style={{ fontSize: "12px" }}>
                                                    <Toggle
                                                        defaultChecked={true}
                                                        icons={{
                                                            checked: "Public",
                                                            unchecked: "Private"
                                                        }}
                                                        onChange={() => {
                                                            setContent_status(!content_status)
                                                        }
                                                        }
                                                        // checked={content_status.includes(i)}
                                                        checked={content_status}
                                                    />
                                                </div>
                                            </></div>
                                        </div>
                                        {!editId && <button className='save-as-draft-container p-3' onClick={() => handleSubmit("3")}>
                                            <RxFileText strokeWidth={0.1} size={30} color='#838383' /> <div>Save as Draft</div>
                                        </button>}
                                        <div>
                                            <button type="button" className="btn btn-primary w-100 py-2 text-center submit-btn" onClick={e => setIsModalOpen(true)}>{editId && handleMangeCrudRight({ userType, options: optionRights, option_id: "4" }) ? "Update and Publish" : "Publish"}{/* <IoMdArrowDropdown size={20} className='float-end' color='white' />*/}</button>
                                        </div>
                                    </>)

                            }
                        </div>
                    </div>


                    {newCurriculamState === 'basic-details' ? <div className='d-flex flex-column justify-content-center align-items-center flex-grow-1 new-curriculam-second-part-section' >
                        <div className='basic_details_img'>
                            <div className='demo-img'>
                                <img src={newcurriculamImage} />
                                <p >Get started by simply clicking the <span>Next</span></p>
                            </div>
                        </div>
                    </div> : load ? (<>
                        <div className='w-100 h-100 flex-center'>
                            <SpinnerComponent />
                        </div>
                    </>) : (<>
                        <div className='overflow-auto w-100 overview-container'>
                            <div className='new-curriculam-second-part-section p-4'>
                                <div className='w-100'>
                                    {newDemoData?.map((subject, subjectIndex) => (
                                        <div key={`subject-preview-${subjectIndex}`} className="subject-preview">
                                            <div
                                                onClick={() => handleSubjectClick(subjectIndex)}
                                                className='indexing mb-2 cursor-pointer d-flex content-list align-items-center px-4 list pt-3 subject_heading_div'
                                            >
                                                <img src={DotsSixVertical} />
                                                <div className='subject_preview_heading'>
                                                    {subjectIndex + 1}.
                                                    <span className='subject_name_preview'>{subject.name}</span>
                                                    <span className='dropdown_arrow'>
                                                        {activeSubjects.includes(subjectIndex) ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
                                                    </span>
                                                </div> {/* Display Subject Name */}
                                            </div>
                                            {activeSubjects.includes(subjectIndex) && subject.topics?.map((topic, topicIndex) => (
                                                <div key={`topic-preview-${subjectIndex}-${topicIndex}`} className="topic-preview" style={{ marginLeft: "34px" }}>
                                                    <div
                                                        onClick={() => handleTopicClick(subjectIndex, topicIndex)}
                                                        className='cursor-pointer d-flex content-list align-items-center list py-3 subject_heading_div topics_preview_heading flex-grow-1'
                                                    >
                                                        <img src={DotsSixVertical} style={{ height: "20px", width: "20px" }} />
                                                        <div style={{ paddingLeft: "10px", display: "flex" }}>
                                                            {subjectIndex + 1}.{topicIndex + 1}
                                                            <span className='subject_name_preview'>{topic.name}</span>
                                                        </div>
                                                        <div className='dropdown_arrow'>
                                                            {activeTopics[subjectIndex]?.includes(topicIndex) ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
                                                        </div>
                                                    </div> {/* Display Topic Name */}
                                                    {activeTopics[subjectIndex]?.includes(topicIndex) && topic.sub_topics?.map((subTopic, subTopicIndex) => (
                                                        <div key={`subtopic-preview-${subjectIndex}-${topicIndex}-${subTopicIndex}`} className="subtopic-preview ml-5 d-flex align-items-center" style={{ marginLeft: "20px", cursor: "pointer" }}>
                                                            <img src={DotsSixVertical} style={{ height: "20px", width: "20px", marginLeft: "10px" }} />
                                                            <div className='d-flex content-list align-items-center list py-3 subject_heading_div subtopic_preview_heading'>
                                                                {subjectIndex + 1}.{topicIndex + 1}.{subTopicIndex + 1}
                                                                <span className='subject_name_preview'>{subTopic.name}</span>
                                                            </div> {/* Display Subtopic Name */}
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    ))}

                                    {/* <div className='curriculum-card mt-4'>
                                        {showTopicImg && showTopicImg?.length === 2 && newDemoData[showTopicImg[0]].topics[showTopicImg[1]].file_data?.map((elm, i) => (
                                            // Only render CardWithImage if file_id is present
                                            elm.file_id ? (
                                                <CardWithImage
                                                    onClickDemo={() => handleDemoContent(i)}
                                                    title={elm.name}
                                                    btnType={elm.mark_demo}
                                                    data={elm}
                                                    type={"pdf"}
                                                    onRemove={() => { editId && elm.id ? handleDeleteFile(elm.id) : removeContentCard(i) }}
                                                />
                                            ) : ""
                                        ))}

                                        {showTopicImg && showTopicImg?.length === 3 && newDemoData[showTopicImg[0]].topics[showTopicImg[1]].sub_topics[showTopicImg[2]].file_data?.map((elm, i) => (
                                            // Only render CardWithImage if file_id is present
                                            elm.file_id ? (
                                                <CardWithImage
                                                    onClickDemo={() => handleDemoContentSubTopic(i)}
                                                    title={elm.name}
                                                    btnType={elm.mark_demo}
                                                    data={elm}
                                                    type={"pdf"}
                                                    onRemove={() => { editId && elm.id ? handleDeleteFile(elm.id) : removeContentCard(i, "sub_topic") }}
                                                />
                                            ) : ""
                                        ))}
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </>)}
                </div>

            </div>
            {/* <CardWithImage />

            <AddLinkModal />

            <SchoolCard /> */}

            {isModalOpen && <ModalAssign isOpen={isModalOpen} setIsOpen={setIsModalOpen} title={title} grade={classDropdown.filter(io => io.id === classId)[0].label} category={categoryDropdown && categoryDropdown.filter(io => io.id === selectCate)[0].label} part={newDemoData?.length} contentData={newDemoData} links={0} handleSubmit={handleSubmit} />}
            {isFileModal && <UploadFile editId={editId} handleUpload={handleTopicWiseImage} topicIds={showTopicImg} isOpen={isFileModal} setIsOpen={setIsFileModal} data={newDemoData} />}
            {topicFileData && <UploadFile editId={editId} handleUpload={handleTopicFileData} topicIds={showTopicImg} isOpen={topicFileData} setIsOpen={setTopicFileData} data={newDemoData} handbookUpload={handbookUpload} />}
            {mediaLinkModalOpen && <UploadMediaLink showTopicImg={showTopicImg} curriculumData={newDemoData} setCurriculumData={setNewDemoData} isOpen={mediaLinkModalOpen} setIsOpen={setMediaLinkModalOpen} />}
        </div >
    )
}

export default NewCurriculam